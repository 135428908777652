@use "sass:map";

@use "variables/layout";

.bar {
  background: #160829;
}

.container {
  display: flex;
  height: #{map.get(layout.$topBarHeight, "mobile")};
  justify-content: space-between;
  position: relative;

  @media (min-width: 768px) {
    height: #{map.get(layout.$topBarHeight, "desktop")};
  }

  @media (min-width: 1524px) {
    overflow: visible;
  }
}

.logo {
  height: 20px;

  @media (min-width: 768px) {
    height: 24px;
  }
}

.column {
  align-items: center;
  display: flex;
  flex: 1;
}

.middleColumn {
  flex: 2;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  position: relative;

  @media (min-width: 992px) {
    display: flex;
  }
}

.title {
  display: block;
  min-width: 100%;
  position: absolute;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  will-change: transform;

  &__animated {
    animation: marquee 15s linear infinite;
    padding-left: 100%;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.lastColumn {
  justify-content: flex-end;
}
