.logo {
  display: block;
  aspect-ratio: 120 / 28;

  svg {
    display: block;
    fill: currentColor;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
  }
}
