@use "ui/colors";
@use "mixins/breakpoint";

.tabPanel {
  display: flex;
  height: 48px;
  margin-bottom: 42px;
  border-radius: 96px;
  background-color: colors.$violet-500;

  @include breakpoint.tablet {
    gap: 8px;
    width: 524px;
    margin: 0 auto 48px;
  }
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 0 16px;
  color: colors.$gray-50;
  border-radius: 100px;
  cursor: pointer;
  background: none;
  border: none;

  &[data-state="active"] {
    background-color: colors.$gray-50;
    color: colors.$violet-900;
  }

  @include breakpoint.tablet {
    flex-direction: row;
    gap: 10px;
    padding: 8px 12px;
  }
}

.icons {
  display: flex;
  gap: 2px;
}
